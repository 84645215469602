<template>
  <div id="login">
    <div class="login-wrap">
      <div class="login-head">
        <figure>
          <img src="@/assets/img/logo.svg" alt="Beaverman Administrator System">
        </figure>
        <h1>Administrator / Login</h1>
      </div>
      <div v-if="err_message" class="err-message">{{ err_message }}</div>
      <form @submit.prevent="onsubmit">
        <p :class="{ error: $v.email.$error }">
          <input type="email" v-model.trim="$v.email.$model" ref="email" name="email" placeholder="Your Email ...">
        </p>
        <p :class="{ error: $v.password.$error }">
          <input type="password" v-model.trim="$v.password.$model" placeholder="Password...">
        </p>
        <button>Login</button>
      </form>
    </div>
  </div>
</template>


<script>
import {apiRequest} from '@/utils/axios/axiosInstance.js';
const { required, email } = require('vuelidate/lib/validators')

export default {
  page_title: "Log in",
  data() {
    return {
      email: null,
      password: null,
      err_message: '',
    }
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required
    },
  },
  created() {
    this.$store.commit('logout');
  },
  mounted() {
    this.$store.commit('setHeadTitle', 'Login');
    this.$refs.email.focus();
  },
  methods: {
    login() {
      this.$store.commit('setLoaderShow', true);
      this.err_message = '';
      
      apiRequest
        .post('/user/login', {
          email: this.email,
          pass:  this.password
        })
        .then( (res) => {
          let data = res.data;
          // console.log(data);
          
          if(data.statusText == 'success') {
            this.$store.commit('setUserSession', res.data.result);
            this.$router.push('/').catch();
          }else{
            this.email        = null;
            this.password     = null;

            if(data.statusText == 'not_found') {
              this.err_message  = 'This account not found.';
            }else{
              this.err_message  = 'Login fail. Please, try again.';
            }

            this.$refs.email.focus();
          }

          // if end process and system stay in this page, hide loader
          this.$store.commit('setLoaderShow', false);
        })
    },
    onsubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.err_message = 'Please, enter all input before submit';
      } else {
        this.login();
      }
    }
  },
}
</script>


<style scoped>
#login {
  padding: 80px 20px;
}
.login-wrap {
  float: none;
  margin: auto;
  padding: 30px 40px 40px 40px;
  border-radius: 10px;
  width: 360px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}
#login .err-message {
  margin: -10px 0 10px 0;
  text-align: center;
  color: #c00;
}
.login-head {
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.login-head img {
  width: 180px;
}
#login p {
  margin-bottom: 5px;
}
#login input,
#login button {
  padding: 10px;
  width: 100%;
}
#login button {
  margin-top: 5px;
}
#login .error input,
#login .error select {
  border-color: #f90;
}
</style>